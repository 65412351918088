import React, { useRef, useState } from "react";
import { patchMatkul } from "../../api";
import FormInput from "../../components/forms/Input";
import FormSelect from "../../components/forms/Select";
import LayoutModal from "../../components/layouts/Modal";
import { saAlert } from "../../helpers";
import SmallButton from "../../components/forms/SmallButton";

const MatkulEdit = ({ matkul, kelompokMatkul, jurusan, onFetchData }) => {
  const tagForm = useRef(null);

  const [modal, setModal] = useState(false);

  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});

  const handleOpen = () => {
    setForm(matkul);
    setModal(true);
  };

  const handleClose = () => {
    if (tagForm && tagForm.current) {
      tagForm.current.reset();
    }
    setForm({});
    setErrors({});
    setModal(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors({});
    try {
      const res = await patchMatkul(matkul.id, form);
      if (res.data === "success") {
        onFetchData();
        handleClose();
        saAlert("success", "Berhasil ubah data");
      }
    } catch (err) {
      if (err.response && err.response.status === 422) {
        setErrors(err.response.data.errors);
      }
    }
  };

  return (
    <>
      <SmallButton type="edit" text="Edit" onClick={handleOpen}/>
      
      <LayoutModal title="Ubah Jam Kuliah" modal={modal} onClose={handleClose}>
        <form ref={tagForm} onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md">
              <FormInput
                type="text"
                label="Kode"
                name="kode"
                value={form.kode}
                onChange={(e) => {
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  });
                }}
                error={errors.kode}
              />
            </div>
            <div className="col-md">
              <FormInput
                type="text"
                label="Nama"
                name="nama"
                value={form.nama}
                onChange={(e) => {
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  });
                }}
                error={errors.nama}
              />
            </div>
          </div>
          <FormInput
            label="RPP Link"
            name="rpp_link"
            value={form.rpp_link}
            onChange={(e) => {
              setForm((prevState) => {
                return { ...prevState, [e.target.name]: e.target.value };
              });
            }}
            error={errors.rpp_link}
          />
          {/* <FormInput
            label="Modul Link"
            name="modul_link"
            value={form.modul_link}
            onChange={(e) => {
              setForm((prevState) => {
                return { ...prevState, [e.target.name]: e.target.value };
              });
            }}
            error={errors.modul_link}
          /> */}
          <FormInput
            label="Bahan Ajar Link"
            name="bahan_ajar_link"
            value={form.bahan_ajar_link}
            onChange={(e) => {
              setForm((prevState) => {
                return { ...prevState, [e.target.name]: e.target.value };
              });
            }}
            error={errors.bahan_ajar_link}
          />
          <div className="row">
            <div className="col-md">
              <FormSelect
                label="Kelompok Mata Kuliah"
                name="kelompok_matkul_id"
                value={form.kelompok_matkul_id ? form.kelompok_matkul_id : ""}
                onChange={(e) => {
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  });
                }}
                error={errors.kelompok_matkul_id}
              >
                <option value="">Pilih Kelompok Mata Kuliah</option>
                {kelompokMatkul.length >= 1 &&
                  kelompokMatkul.map((i, index) => {
                    return (
                      <React.Fragment key={index}>
                        <option value={i.id}>{i.nama}</option>
                      </React.Fragment>
                    );
                  })}
              </FormSelect>
            </div>
            <div className="col-md">
              <FormInput
                type="number"
                label="SKS"
                name="sks"
                value={form.sks ? form.sks : ""}
                onChange={(e) => {
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  });
                }}
                error={errors.sks}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md">
              <FormSelect
                label="Jenis Penilaian"
                name="jenis_penilaian_id"
                value={form.jenis_penilaian_id}
                onChange={(e) => {
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  });
                }}
                error={errors.jenis_penilaian_id}
              >
                <option value="">Pilih Jenis Penilaian</option>
                <option value="1">Umum</option>
                <option value="2">Per Jenjang</option>
                <option value="3">Mutlak</option>
                <option value="4">D2</option>
              </FormSelect>
            </div>
            <div className="col-md">
              <FormSelect
                label="Jenis Matkul"
                name="jenis_matkul"
                value={form.jenis_matkul}
                onChange={(e) => {
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  });
                }}
                error={errors.jenis_matkul}
              >
                <option value="">Pilih</option>
                <option value="1">Praktek</option>
                <option value="2">Teori</option>
              </FormSelect>
            </div>
            <div className="col-md">
              <FormSelect
                label="Jurusan"
                name="jurusan_id"
                value={form.jurusan_id}
                onChange={(e) => {
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  });
                }}
                error={errors.jurusan_id}
              >
                <option value="">Umum</option>
                {jurusan.length > 0 &&
                  jurusan.map((j, index) => {
                    return (
                      <React.Fragment key={index}>
                        <option value={j.id}>{j.nama}</option>
                      </React.Fragment>
                    );
                  })}
              </FormSelect>
            </div>
          </div>
          <div className="btn-group">
            <button type="submit" className="btn btn-sm btn-success-2">
              Simpan Perubahaan
            </button>
            <button
              type="button"
              className="btn btn-sm btn-dark border-0"
              onClick={handleClose}
            >
              <i className="fa fa-fw fa-sm fa-arrow-left"/> Kembali
            </button>
          </div>
        </form>
      </LayoutModal>
    </>
  );
};

export default MatkulEdit;
