import React, { useCallback, useEffect, useState } from 'react'
import SmallButton from '../../components/forms/SmallButton'
import LayoutModal, { NewSubmitButton } from '../../components/layouts/Modal';
import TableNew, { Tbody, Td, Th, Thead, Tr } from '../../components/layouts/TableNew';
import { defaultFilterTahun, FilterSearch, FilterTahun, LayoutAddBtn } from '../../components/layouts/TopContent';
import { deleteRencanaPengajaran, fetchRencanaPengajaran, patchRencanaPengajaran, postRencanaPengajaran } from '../../api';
import FormTextarea from '../../components/forms/Textarea';
import FormSelect from '../../components/forms/Select';
import { saAlert, saConfirm } from '../../helpers';

function RencanaPengajaran({ matkul, onFetchData }) {

    const [open, setOpen] = useState(false);
    const [openCreate, setOpenCreate] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [tahun, setTahun] = useState(defaultFilterTahun);
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState("");
    const [form, setForm] = useState({});
    const [errors, setErrors] = useState({});
    const pertemuan = [1,2,3,4,5,6,7,8,9,10,11,12];
    const [rencanaPengajaran, setRencanaPengajaran] = useState([]);
    const [loading, setLoading] = useState(true);

    const fetchDataRP = useCallback( async () => {
        try {
            const res = await fetchRencanaPengajaran(page, `matkul_id=${matkul.id}&tahun=${tahun}&search=${search}`);
            if (res.data.rencana_pengajaran) {
                setRencanaPengajaran(res.data.rencana_pengajaran);
                setLoading(false);
            }
        } catch (error) {
            console.log(error.response);
            setLoading(false);
        }
    }, [matkul.id, page, tahun, search]);

    useEffect(() => {
        if (open) {
            fetchDataRP();
        }
    }, [fetchDataRP]);

    const handleOpen = () => {
        fetchDataRP();
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
        setOpenCreate(false);
        setOpenEdit(false);
        setForm({});
        setErrors({});
        setSearch("");
    }

    const handleOpenCreate = () => {
        setOpenCreate(true);
    }

    const handleCloseCreate = () => {
        setOpenCreate(false);
        setForm({});
        setErrors({});
        setSearch("");
    }

    const handleChangeCreate = (e) => {
        setForm((prevState) => {
            return { ...prevState , [e.target.name] : e.target.value };
        });
    }

    const handleSubmitCreate = async (e) => {
        e.preventDefault();
        setErrors({});

        try {
            const res = await postRencanaPengajaran(form, `matkul_id=${matkul.id}&tahun=${tahun}`);
            if (res.data.status === 'success') {
                onFetchData();
                fetchDataRP();
                handleCloseCreate();
                saAlert(res.data.status, res.data.msg);
            } else if (res.data.status === 'error') {
                saAlert(res.data.status, res.data.msg);
            }
        } catch (error) {
            console.log(error.response);
            setErrors(error.response.data.errors);
        }
    }

    const handleOpenEdit = (item) => {
        setOpenEdit(true);
        setForm(item);
    }

    const handleCloseEdit = () => {
        setOpenEdit(false);
        setForm({});
        setErrors({});
        setSearch("");
    }

    const handleChangeEdit = (e) => {
        setForm((prevState) => {
            return { ...prevState , [e.target.name] : e.target.value };
        });
    }

    const handleSubmitEdit = async (e) => {
        e.preventDefault();
        setErrors({});

        try {
            const res = await patchRencanaPengajaran(form.id, form, `matkul_id=${matkul.id}&tahun=${tahun}`);
            if (res.data.status === 'success') {
                onFetchData();
                fetchDataRP();
                handleCloseEdit();
                saAlert(res.data.status, res.data.msg);
            } else if (res.data.status === 'error') {
                saAlert(res.data.status, res.data.msg);
            }
        } catch (error) {
            console.log(error.response);
            setErrors(error.response.data.errors);
        }
    }

    const handleDelete = async (id) => {
        saConfirm("warning", "Yakin hapus Rencana Pengajaran ini?").then(async (res) => {
            if (res.isConfirmed) {
                try {
                    const res = await deleteRencanaPengajaran(id);
                    if (res.data.status === 'success') {
                        onFetchData();
                        fetchDataRP();
                        saAlert(res.data.status, res.data.msg);
                    }
                } catch (error) {
                    console.log(error.response);      
                }
            }
        })
    };

    return (
        <div>
            <SmallButton onClick={handleOpen} margin="me-1" type="custom" buttonColor="dark" icon="book" text="Rencana">Rencana</SmallButton>

            <LayoutModal title="Rencana Pengajaran" modal={open} onClose={handleClose}>

                <div className="row justify-content-end mb-2">
                    <div className="col-md-3">
                        <FilterTahun onChange={(res) => setTahun(res)} />
                    </div>
                    <div className="col-md-5">
                        <FilterSearch
                            onChange={(res) => {
                                setSearch(res);
                                setPage(1);
                            }}
                        />
                    </div>
                </div>

                {!openCreate && !openEdit &&
                    <>
                        <LayoutAddBtn onClick={handleOpenCreate} add="Tambah Rencana" />

                        <TableNew>
                            <Thead>
                                <Tr className="text-center">
                                    <Th>No</Th>
                                    <Th>Pertemuan</Th>
                                    <Th>Tahun</Th>
                                    <Th>Materi</Th>
                                    <Th><i className='fa fa-fw fa-cog' /></Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {!loading ?
                                    rencanaPengajaran.length > 0 ?
                                        rencanaPengajaran.map((item, index) => (
                                            <Tr key={index}>
                                                <Td className="align-middle text-center">{index + 1}</Td>
                                                <Td className="align-middle text-center">{item.pertemuan}</Td>
                                                <Td className="align-middle text-center">{item.tahun}</Td>
                                                <Td>{item.materi}</Td>
                                                <Td className="align-middle text-center text-nowrap">
                                                    <SmallButton onClick={() => handleOpenEdit(item)} margin="me-1" type="edit" text="Edit" />
                                                    <SmallButton onClick={() => handleDelete(item.id)} type="delete" text="Hapus" />
                                                </Td>
                                            </Tr>
                                        )) : (
                                            <Tr>
                                                <Td colSpan={5} className="text-center">Tidak ada data</Td>
                                            </Tr>
                                        ) : (
                                        <Tr>
                                            <Td colSpan={5} className="text-center"><i className='fa fa-fw fa-spinner fa-spin' /> Loading...</Td>
                                        </Tr>
                                    )
                                }
                            </Tbody>
                        </TableNew>
                    </>
                }

                {openCreate &&
                    <div>
                        <h5 className='fw-semibold text-black'>Tambah Rencana</h5>
                        <form onSubmit={handleSubmitCreate}>
                            <div className='row'>
                                <div className='col-lg-3'>
                                    <FormSelect
                                        label="Pertemuan"
                                        name="pertemuan"
                                        onChange={handleChangeCreate}
                                        error={errors.pertemuan ? errors.pertemuan : null}
                                    >
                                        <option value="">Pilih Pertemuan</option>
                                        {pertemuan.map((item, index) => (
                                            <option key={index} value={item}>Pertemuan {item}</option>
                                        ))}
                                    </FormSelect>
                                </div>
                                <div className='col-lg-9'>
                                    <FormTextarea
                                        label="Materi"
                                        name="materi"
                                        rows="5"
                                        error={errors.materi ? errors.materi : null}
                                        onChange={handleChangeCreate}
                                    />
                                </div>
                                <NewSubmitButton submitText="Simpan" onClose={handleCloseCreate} />
                            </div>
                        </form>
                    </div>
                }

                {openEdit &&
                    <div>
                        <h5 className='fw-semibold text-black'>Edit Rencana</h5>
                        <form onSubmit={handleSubmitEdit}>
                            <div className='row'>
                                <div className='col-lg-3'>
                                    <FormSelect
                                        label="Pertemuan"
                                        name="pertemuan"
                                        error={errors.pertemuan ? errors.pertemuan : null}
                                        onChange={handleChangeEdit}
                                        value={form.pertemuan}
                                    >
                                        <option value="">Pilih Pertemuan</option>
                                        {pertemuan.map((item, index) => (
                                            <option key={index} value={item}>Pertemuan {item}</option>
                                        ))}
                                    </FormSelect>
                                </div>
                                <div className='col-lg-9'>
                                    <FormTextarea
                                        label="Materi"
                                        name="materi"
                                        rows="5"
                                        error={errors.materi ? errors.materi : null}
                                        value={form.materi}
                                        onChange={handleChangeEdit}
                                    />
                                </div>
                                <NewSubmitButton submitText="Simpan" onClose={handleCloseEdit} />
                            </div>
                        </form>
                    </div>
                }
            </LayoutModal>
        </div>
    )
}

export default RencanaPengajaran