import React, { useCallback, useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { fetchKelompokMatkul, fetchMatkul } from "../../api";
import {
  FilterJenisMatkul,
  FilterJurusan,
  FilterSearch,
} from "../../components/layouts/TopContent";
import PageLoading from "../../components/pages/Loading";
import PageNoData from "../../components/pages/NoData";
import SectionPagination from "../../components/sections/Pagination";
import { userState } from "../../storage/auth";
import { titleState } from "../../storage/pages";
import MatkulCreate from "./Create";
import MatkulEdit from "./Edit";
import TableNew, { Tbody, Td, Th, Thead, Tr } from "../../components/layouts/TableNew";
import RencanaPengajaran from "./RencanaPengajaran";

const JamkulAkademik = () => {
  const setTitle = useSetRecoilState(titleState);
  const user = useRecoilValue(userState);
  const [isLoaded, setIsLoaded] = useState(false);

  const [items, setItems] = useState([]);
  const [itemsJurusan, setItemsJurusan] = useState({});

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [jurusan, setJurusan] = useState("");
  const [jenisMatkul, setJenisMatkul] = useState("");

  const fetchData = useCallback(async () => {
    try {
      const res = await fetchMatkul(
        page,
        `include_jurusan=true&search=${search}&jurusan=${jurusan}&
        jenis_matkul=${jenisMatkul}`
      );
      if (res.data) {
        setItems(res.data.matkul);
        setItemsJurusan(res.data.jurusan);
      }
      setIsLoaded(true);
    } catch (err) {
      console.log(err);
    }
  }, [page, search, jurusan, jenisMatkul]);

  useEffect(() => {
    setTitle("Data Mata Kuliah");
    fetchData();
  }, [setTitle, fetchData]);

  // to get Kelompok matkul for edit and create matkul
  const [kelompokMatkul, setKelompokMatkul] = useState({});
  useEffect(() => {
    const getKelompokMatkul = async () => {
      try {
        const res = await fetchKelompokMatkul("all");
        setKelompokMatkul(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    getKelompokMatkul();
  }, []);

  if (isLoaded) {
    return (
      <>
        {user.cabang === "pusat" && (
          <MatkulCreate
            onFetchData={fetchData}
            jurusan={itemsJurusan}
            kelompokMatkul={kelompokMatkul}
          />
        )}
        <div className="row mb-2">
          <div className="col-md-4">
            <FilterJurusan
              onChange={(res) => {
                setJurusan(res);
                setPage(1);
              }}
            />
          </div>
          <div className="col-md-4">
            <FilterJenisMatkul
              onChange={(res) => {
                setJenisMatkul(res);
                setPage(1);
              }}
            />
          </div>
          <div className="col-md-4">
            <FilterSearch
              onChange={(res) => {
                setSearch(res);
                setPage(1);
              }}
            />
          </div>
        </div>
        {items.data.length > 0 ? (
          <div className="row">
            <div className="col-2 pe-0">
              <TableNew>
                <Thead>
                  <Tr className="text-center text-nowrap">
                    <Th>No</Th>
                    <Th>Kode MK</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {items.data.map((i, index) => {
                    return (
                      <Tr key={index}>
                        <Td className="text-nowrap">{index + items.from}</Td>
                        <Td className="text-nowrap">{i.kode}</Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </TableNew>
            </div>
            <div className="col-8 px-0">
              <TableNew>
                <Thead>
                  <Tr className="text-center text-nowrap">
                    <Th>Nama Mata Kuliah</Th>
                    <Th>Kelompok MK</Th>
                    <Th>Kredit (SKS)</Th>
                    <Th>Jenis Penilaian</Th>
                    <Th>Jenis Matkul</Th>
                    <Th>Jurusan</Th>
                    <Th>Silabus dan RPP</Th>
                    {/* <Th>Modul</Th> */}
                    <Th>Bahan Ajar</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {items.data.map((i, index) => {
                    return (
                      <Tr key={index}>
                        <Td className="text-nowrap">{i.nama}</Td>
                        <Td className="text-nowrap">{i.kelompok_matkul && i.kelompok_matkul.nama}</Td>
                        <Td className="text-nowrap">{i.sks}</Td>
                        <Td className="text-nowrap">
                          {parseInt(i.jenis_penilaian_id) === 1 && "Umum"}
                          {parseInt(i.jenis_penilaian_id) === 2 && "Per Jenjang"}
                          {parseInt(i.jenis_penilaian_id) === 3 && "Mutlak"}
                          {parseInt(i.jenis_penilaian_id) === 4 && "D2"}
                        </Td>
                        <Td className="text-nowrap">
                          {parseInt(i.jenis_matkul) === 1 && "Praktek"}
                          {parseInt(i.jenis_matkul) === 2 && "Teori"}
                        </Td>
                        <Td className="text-nowrap">
                          {i.jurusan_id
                            ? itemsJurusan.length > 0 &&
                              itemsJurusan
                                .filter((ij) => parseInt(ij.id) === parseInt(i.jurusan_id))
                                .map((ij, index) => {
                                  return (
                                    <React.Fragment key={index}>
                                      {ij.nama}
                                    </React.Fragment>
                                  );
                                })
                            : "Umum"}
                        </Td>
                        <Td className="text-nowrap">
                          {i.rpp_link && (
                            <span
                              type="button"
                              className="text-primary"
                              onClick={() => window.open(i.rpp_link)}
                            >
                              {i.rpp_link && 'Klik disini'}
                            </span>
                          )}
                        </Td>
                        {/* <Td className="text-nowrap">
                          {i.modul_link && (
                            <span
                              type="button"
                              className="text-primary"
                              onClick={() => window.open(i.modul_link)}
                            >
                              {i.modul_link && 'Klik disini'}
                            </span>
                          )}
                        </Td> */}
                        <Td className="text-nowrap">
                          {i.bahan_ajar_link && (
                            <span
                              type="button"
                              className="text-primary"
                              onClick={() => window.open(i.bahan_ajar_link)}
                            >
                              {i.bahan_ajar_link && 'Klik disini'}
                            </span>
                          )}
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </TableNew>
            </div>
            <div className="col-2 ps-0">
              {user.group === "Akademik" && (
                <TableNew copy={false}>
                  <Thead>
                    <Tr className="text-center text-nowrap">
                      <Th><i className="fa fa-fw fa-cog"/></Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {items.data.map((i, index) => {
                      return (
                        <Tr key={index}>
                          <Td className="text-nowrap">
                            <div className="d-flex justify-content-center align-items-center">
                              <RencanaPengajaran matkul={i} onFetchData={fetchData}/>
                              <MatkulEdit
                                matkul={i}
                                kelompokMatkul={kelompokMatkul}
                                jurusan={itemsJurusan}
                                onFetchData={fetchData}
                              />
                            </div>
                          </Td>
                        </Tr>
                      );
                    })}
                  </Tbody>
                </TableNew>
              )}
            </div>
          </div>
        ) : (
          <PageNoData />
        )}
        <SectionPagination
          links={items.links}
          onChangePage={(res) => setPage(res)}
        />
      </>
    );
  } else {
    return <PageLoading />;
  }
};

export default JamkulAkademik;
