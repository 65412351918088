import React, { forwardRef, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { fetchingData, fetchJurusan } from "../../api";
import { isWindowView } from "../../helpers";
import { fetchCabang } from "../../modules/cabang/apis";
import { useRecoilValue } from "recoil";
import { angkatanState } from "../../storage/pages";

// Default

export const defaultFilterListTahun = [
  2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026,
];
export const defaultFilterTahun = 2025;
// export const defaultFilterAngkatan = 2026;
// export const defaultFilterTahunAjaran = 2022;
// export const defaultFilterSemesterAjaran = 2022;

// End Default

export const LayoutAddBtn = (props) => {
  const {modalButton, dataBsTarget, link, add, onClick } = props;
  return (
    <div className="row justify-content-end mb-3">
      <div className="col-lg-3">
        {link && (
          <Link
            className={`btn btn-sm btn-warning-2 fw-semibold shadow rounded-3 w-100 ${
              isWindowView() === false && "btn-sm"
            }`}
            to={link}
            dangerouslySetInnerHTML={{
              __html: '<i class="fa fa-fw fa-pen fa-sm me-1"></i>' + add,
            }}
          ></Link>
        )}
        {onClick && (
          <button
            className={`btn btn-sm btn-warning-2 fw-semibold shadow rounded-3 w-100 ${
              isWindowView() === false && "btn-sm"
            }`}
            onClick={onClick}
            dangerouslySetInnerHTML={{
              __html: '<i class="fa fa-fw fa-pen fa-sm me-1"></i>' + add,
            }}
          ></button>
        )}
        {modalButton && (
          <button
            className={`btn btn-sm btn-warning-2 fw-semibold shadow rounded-3 w-100 ${
              isWindowView() === false && "btn-sm"
            }`}
            onClick={onClick}
            dangerouslySetInnerHTML={{
              __html: '<i class="fa fa-fw fa-pen fa-sm me-1"></i>' + add,
            }}
            data-bs-toggle="modal" 
            data-bs-target={`#${dataBsTarget}`}
          ></button>
        )}
      </div>
    </div>
  );
};

export const LayoutTopFilter = (props) => {
  return (
    <>
      <div className="row mb-3 justify-content-end">
        {props.onChangeSearch && (
          <div className="col-md-6">
            <div className="input-group rounded-3">
              <span className="input-group-text bg-none border-end-0 pe-0"><i className="fa fa-fw fa-sm fa-search"></i></span>
              <input
                onKeyUp={(e) => props.onChangeSearch(e)}
                className="form-control form-control-sm border-start-0 shadow-none"
                type="text"
                placeholder="Search . . ."
              />
            </div>
          </div>
        )}
      </div>
      {props.onChangeCabang && (
        <div className="col-md-4 mb-3">
          <select
            onChange={(e) => props.onChangeCabang(e)}
            className="custom-select"
          >
            <option value="">Semua Cabang</option>
            <option value="Pusat">Pusat</option>
            <option value="Denpasar">Denpasar</option>
            <option value="Singaraja">Singaraja</option>
            <option value="Karangasem">Karangasem</option>
          </select>
        </div>
      )}
      {props.onChangeBulan && (
        <div className="col-md-4 mb-3">
          <select
            onChange={(e) => props.onChangeBulan(e)}
            className="custom-select"
          >
            <option value="1">Januari</option>
            <option value="2">Februari</option>
            <option value="3">Maret</option>
            <option value="4">April</option>
            <option value="5">Mei</option>
            <option value="6">Juni</option>
            <option value="7">Juli</option>
            <option value="8">Agustus</option>
            <option value="9">September</option>
            <option value="10">Oktober</option>
            <option value="11">November</option>
            <option value="12">Desember</option>
          </select>
        </div>
      )}
    </>
  );
};

// Pagination
export const FilterPagination = (props) => {
  const { onChange } = props;
  return (
    <div>
      <label htmlFor="" className="fw-semibold text-primary">Data per Page</label>
      <select
        className="form-select form-select-sm form-select-custom border-silver rounded-3"
        onChange={(e) => {
          onChange(e.target.value);
          fetchingData();
        }}
      >
        <option value="10">10</option>
        <option value="25">25</option>
        <option value="50">50</option>
        <option value="100">100</option>
      </select>
    </div>
  );
};

// Search
export const FilterSearch = forwardRef((props, ref) => {
  const { label, hideLabel, placeholder, onChange, className } = props;

  let timer = 0;
  const handleChange = (val) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      onChange(val);
      fetchingData();
    }, 1000);
  };

  return (
    <div>
      {!hideLabel &&
        <label htmlFor="" className="fw-semibold text-primary">{label ? label : "Search"}</label>
      }
      <input
        type="text"
        ref={ref}
        className={`form-control form-control-sm form-control-custom rounded-3 border-silver ${className}`}
        placeholder={placeholder ? placeholder : "Search . . ."}
        onKeyUp={(e) => handleChange(e.target.value)}
      />
    </div>
  );
});

export const defaultFilterBulan = () => {
  let d = new Date();
  let month = d.getMonth() + 1;
  let date = d.getDate();

  if (date > 25) {
    let hasil = month + 1;
    if (hasil === 13) {
      hasil = 1;
    }
    return hasil.toString();
  } else {
    let hasil = month;
    return hasil.toString();
  }
};

// Pagination
export const FilterBulan = (props) => {
  const { onChange, value, all } = props;

  return (
    <div>
      <label htmlFor="" className="fw-semibold text-primary">Pilih Bulan</label>
      <select
        className="form-select form-select-sm form-select-custom border-silver rounded-3"
        onChange={(e) => {
          onChange(e.target.value);
          fetchingData();
        }}
        defaultValue={value ? value : defaultFilterBulan()}
      >
        {all && <option value="all">Semua</option>}
        <option value="1">Januari</option>
        <option value="2">Februari</option>
        <option value="3">Maret</option>
        <option value="4">April</option>
        <option value="5">Mei</option>
        <option value="6">Juni</option>
        <option value="7">Juli</option>
        <option value="8">Agustus</option>
        <option value="9">September</option>
        <option value="10">Oktober</option>
        <option value="11">November</option>
        <option value="12">Desember</option>
      </select>
    </div>
  );
};

// Angkatan
export const FilterAngkatan = forwardRef((props, ref) => {
  const { onChange, value } = props;
  const defAngkatan = useRecoilValue(angkatanState);

  return (
    <div>
      <label htmlFor="" className="fw-semibold text-primary">Pilih Angkatan</label>
      <select
        ref={ref}
        className="form-select form-select-sm form-select-custom border-silver rounded-3"
        onChange={(e) => {
          onChange(e.target.value);
          fetchingData();
        }}
        defaultValue={value ? value : defAngkatan}
      >
        {defaultFilterListTahun.length > 0 &&
          defaultFilterListTahun.map((i, index) => {
            return (
              <React.Fragment key={index}>
                <option value={i}>{i}</option>
              </React.Fragment>
            );
          })}
      </select>
    </div>
  );
});

// Jurusan
export const FilterJurusan = (props) => {
  const { onChange } = props;

  const [items, setItems] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await fetchJurusan("all");
        setItems(res.data);
      } catch (err) {
        console.log(err);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <label htmlFor="" className="fw-semibold text-primary">Jurusan</label>
      <select
        className="form-select form-select-sm form-select-custom border-silver rounded-3"
        onChange={(e) => {
          onChange(e.target.value);
          fetchingData();
        }}
      >
        <option value="">Pilih Jurusan</option>
        {items.length >= 1 &&
          items.map((i, index) => {
            return (
              <React.Fragment key={index}>
                <option value={i.id}>
                  {index + 1}. {i.nama}
                </option>
              </React.Fragment>
            );
          })}
      </select>
      {/* <span className="text-danger">
        Note : Saat ini filter jurusan hanya dapat digunakan untuk Mhs angkatan
        2021
      </span> */}
    </div>
  );
};

// Jenjang
export const FilterJenjang = (props) => {
  const { notNull = false, value, onChange } = props;
  return (
    <div>
      <label htmlFor="" className="fw-semibold text-primary">Jenjang</label>
      <select
        className="form-select form-select-sm form-select-custom border-silver rounded-3"
        defaultValue={value}
        onChange={(e) => {
          onChange(e.target.value);
          fetchingData();
        }}
      >
        {!notNull && <option value="">Pilih Jenjang</option>}
        <option value="D1">Program 1 Tahun</option>
        <option value="D2">Program 2 Tahun</option>
      </select>
    </div>
  );
};

export const FilterPeriode = (props) => {
  const { notNull = false, value, onChange } = props;
  return (
    <div className="bg-light p-1 rounded">
      <label htmlFor="">Periode</label>
      <select
        className="form-control"
        defaultValue={value}
        onChange={(e) => {
          onChange(e.target.value);
          fetchingData();
        }}
      >
        {!notNull && <option value="">Pilih Periode</option>}
        <option value="1">1</option>
        <option value="2">2</option>
      </select>
    </div>
  );
};

// Status
export const FilterStatus = (props) => {
  const { onChange } = props;
  return (
    <div>
      <label htmlFor="" className="fw-semibold text-primary">Status Mahasiswa</label>
      <select
        className="form-select form-select-sm form-select-custom border-silver rounded-3"
        onChange={(e) => {
          onChange(e.target.value);
          fetchingData();
        }}
      >
        <option value="">Pilih Status</option>
        <option value="0" className="text-success">
          Aktif
        </option>
        <option value="9" className="text-danger">
          Nonaktif
        </option>
      </select>
    </div>
  );
};

// Status general
export const FilterStatusGeneral = (props) => {
  const { onChange } = props;
  return (
    <div>
      <label htmlFor="" className="fw-semibold text-primary">Status</label>
      <select
        className="form-select form-select-sm form-select-custom border-silver rounded-3"
        onChange={(e) => {
          onChange(e.target.value);
          fetchingData();
        }}
      >
        <option value="">Pilih Status</option>
        <option value="0" className="fw-semibold text-success">
          Aktif
        </option>
        <option value="9" className="fw-semibold text-danger">
          Nonaktif
        </option>
      </select>
    </div>
  );
};

// StatusPendaftar
export const FilterStatusPendaftar = (props) => {
  const { onChange } = props;
  return (
    <div>
      <label htmlFor="" className="fw-semibold text-primary">Status Pendaftar</label>
      <select
        className="form-select form-select-sm form-select-custom border-silver rounded-3"
        onChange={(e) => {
          onChange(e.target.value);
          fetchingData();
        }}
      >
        <option value="">Pilih Status</option>
        <option value="0">Belum Daftar</option>
        <option value="1">Sudah Daftar</option>
        <option value="2">Sudah DU</option>
        <option value="3">Sudah Cair</option>
        <option value="4">Mhs Aktif</option>
        <option value="9">Batal Daftar</option>
      </select>
    </div>
  );
};

// Status internship
export const FilterStatusInternship = (props) => {
  const { value = "", onChange } = props;
  return (
    <div>
      <label htmlFor="" className="fw-semibold text-primary">Status Internship</label>
      <select
        className="form-select form-select-sm form-select-custom border-silver rounded-3"
        value={value}
        onChange={(e) => {
          onChange(e.target.value);
          fetchingData();
        }}
      >
        <option value="">Pilih Status</option>
        <option value="1" className="fw-semibold text-success">
          Aktif
        </option>
        <option value="0" className="fw-semibold text-danger">
          Nonaktif
        </option>
      </select>
    </div>
  );
};

// tahun ajaran
export const FilterTahunAjaran = (props) => {
  const { valueTahun, valueSemester, onChangeTahun, onChangeSemester } = props;

  return (
    <div>
      <label htmlFor="" className="fw-semibold text-primary">Tahun Ajaran</label>
      <div className="row">
        <div className="col-lg mb-2">
          <select
            className="form-select form-select-sm form-select-custom border-silver rounded-3"
            onChange={(e) => {
              onChangeTahun(e.target.value);
              fetchingData();
            }}
            defaultValue={valueTahun ? valueTahun : defaultFilterTahun}
          >
            {defaultFilterListTahun.length > 0 &&
              defaultFilterListTahun.map((i, index) => {
                return (
                  <React.Fragment key={index}>
                    <option value={i}>{i}</option>
                  </React.Fragment>
                );
              })}
          </select>
        </div>
        <div className="col-lg mb-2">
          <select
            className="form-select form-select-sm form-select-custom border-silver rounded-3"
            onChange={(e) => {
              onChangeSemester(e.target.value);
              fetchingData();
            }}
            defaultValue={valueSemester}
          >
            <option value="">Pilih Semester</option>
            <option value="1">Ganjil</option>
            <option value="2">Genap</option>
          </select>
        </div>
      </div>
    </div>
  );
};

// tanggal
export const FilterTanggal = (props) => {
  const { onChange, value } = props;

  return (
    <div>
      <label htmlFor="" className="fw-semibold text-primary">Pilih Tanggal</label>
      <input
        type="date"
        className="form-control form-control-sm form-control-custom border-silver rounded-3"
        onChange={(e) => {
          onChange(e.target.value);
          fetchingData();
        }}
        defaultValue={value}
      />
    </div>
  );
};

// cabang
export const FilterCabang = (props) => {
  const { onChange, value, pusat } = props;

  return (
    <div>
      <label htmlFor="" className="fw-semibold text-primary">Pilih Cabang</label>
      <select
        className="form-select form-select-sm form-select-custom border-silver rounded-3"
        onChange={(e) => {
          onChange(e.target.value);
          fetchingData();
        }}
        defaultValue={value}
      >
        <option value="">Semua</option>
        {pusat && <option value="pusat">Pusat</option>}
        <option value="denpasar">Denpasar</option>
        <option value="singaraja">Singaraja</option>
        <option value="karangasem">Karangasem</option>
      </select>
    </div>
  );
};

export const FilterCabangDinamis = (props) => {
  const { value = "", onChange } = props;
  const [cabang, setCabang] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const res = await fetchCabang("all");
      setCabang(res.data.cabang);
    };

    fetchData();
  }, []);

  return (
    <div>
      <label htmlFor="" className="fw-semibold text-primary">Cabang</label>
      <select
        className="form-select form-select-sm form-select-custom border-silver rounded-3"
        onChange={(e) => {
          onChange(e.target.value);
          fetchingData();
        }}
        defaultValue={value}
      >
        <option value="">Pilih</option>
        {cabang.length > 0 &&
          cabang.map((c, index) => {
            return (
              <React.Fragment key={index}>
                <option value={c.id}>{c.nama}</option>
              </React.Fragment>
            );
          })}
      </select>
    </div>
  );
};

// tahun
export const FilterTahun = (props) => {
  const { onChange, value } = props;

  return (
    <div>
      <label htmlFor="" className="fw-semibold text-primary">Pilih Tahun</label>
      <select
        className="form-select form-select-sm form-select-custom border-silver rounded-3"
        onChange={(e) => {
          onChange(e.target.value);
          fetchingData();
        }}
        defaultValue={value ? value : defaultFilterTahun}
      >
        {defaultFilterListTahun.length > 0 &&
          defaultFilterListTahun.map((i, index) => {
            return (
              <React.Fragment key={index}>
                <option value={i}>{i}</option>
              </React.Fragment>
            );
          })}
      </select>
    </div>
  );
};

// kddb
export const FilterKdbb = (props) => {
  const { onChange } = props;

  return (
    <div>
      <label htmlFor="" className="fw-semibold text-primary">KDBB</label>
      <select
        className="form-select form-select-sm form-select-custom border-silver rounded-3"
        onChange={(e) => {
          onChange(e.target.value);
          fetchingData();
        }}
      >
        <option value="">Semua</option>
        <option value="1">KDBB</option>
        <option value="9">Reguler</option>
      </select>
    </div>
  );
};

// Jenis Matkul
export const FilterJenisMatkul = (props) => {
  const { onChange } = props;

  return (
    <div>
      <label htmlFor="" className="fw-semibold text-primary">Jenis Matkul</label>
      <select
        className="form-select form-select-sm form-select-custom border-silver rounded-3"
        onChange={(e) => {
          onChange(e.target.value);
          fetchingData();
        }}
      >
        <option value="">Pilih</option>
        <option value="1">Praktek</option>
        <option value="2">Teori</option>
      </select>
    </div>
  );
};

// Mhs Ojt
export const FilterMhsOjt = (props) => {
  const { value = "", onChange } = props;

  return (
    <div>
      <label htmlFor="" className="fw-semibold text-primary">Mhs OJT</label>
      <select
        className="form-select form-select-sm form-select-custom border-silver rounded-3"
        onChange={(e) => {
          onChange(e.target.value);
          fetchingData();
        }}
        defaultValue={value}
      >
        <option value="">all</option>
        <option value="yes">Active</option>
        <option value="no">Nonactive</option>
      </select>
    </div>
  );
};

// Mhs Ojt
export const FilterPagiSore = (props) => {
  const { value = "", onChange } = props;

  return (
    <div>
      <label htmlFor="" className="fw-semibold text-primary">Kelas Pagi/Malam</label>
      <select
        className="form-select form-select-sm form-select-custom border-silver rounded-3"
        onChange={(e) => {
          onChange(e.target.value);
          fetchingData();
        }}
        defaultValue={value}
      >
        <option value="">Semua</option>
        <option value="pagi">Pagi</option>
        <option value="sore">Sore</option>
      </select>
    </div>
  );
};
